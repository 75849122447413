import React from "react";
import "../App";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const { t } = useTranslation();
  const projects = [
    {
      id: 1,
      text: `${t("projects-list-item-one")}`,
    },
    {
      id: 2,
      text: `${t("projects-list-item-two")}`,
    },
    {
      id: 3,
      text: `${t("projects-list-item-three")}`,
    },

    {
      id: 4,
      text: `${t("projects-list-item-four")}`,
    },

    {
      id: 5,
      text: `${t("projects-list-item-five")}`,
    },
    {
      id: 6,
      text: `${t("projects-list-item-six")}`,
    },

    {
      id: 7,
      text: `${t("projects-list-item-seven")}`,
    },

    {
      id: 8,
      text: `${t("projects-list-item-eight")}`,
    },
  ];

  return (
    <div className="projects">
      <div className="projects-heading">
        <h1>{t("projects-heading")}</h1>
      </div>
      <div className="projects-list">
        {projects.map((project) => (
          <ul key={project.id}>
            <li>{project.text}</li>
          </ul>
        ))}
      </div>

      <div className="projects-p1">
        <p>{t("projects-p1")}</p>
      </div>
    </div>
  );
};

export default Projects;
