import React, { useRef, useState } from "react";
import "../App";
import { Link } from "react-router-dom";
import sigmaLogo from "../assets/logo sigma.jpg";
import { useTranslation } from "react-i18next";
import { FaBars, FaTimes } from "react-icons/fa";
import i18next from "i18next";
import { useLocation } from "react-router-dom";

const AppBar = () => {
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("lang") ?? "bg"
  );
  const { t } = useTranslation();

  const navRef = useRef();

  const location = useLocation();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const navigateToPageHandler = () => {
    if (navRef.current.classList.contains("responsive_nav")) {
      navRef.current.classList.toggle("responsive_nav");
    }

    return;
  };

  const activeClassChecker = (value) => {
    if (location.pathname === value) {
      return "active";
    }

    return null;
  };

  activeClassChecker();

  const onLanguageChangeHandler = (value) => {
    setSelectedLang(value);
    i18next.changeLanguage(value);
    localStorage.setItem("lang", value);
  };

  return (
    <header>
      <img
        src={sigmaLogo}
        alt="#"
        className="logo"
        style={{
          width: "95px",
          height: "100%",
          marginLeft: "50px",
          objectFit: "cover",
        }}
      />

      <nav ref={navRef}>
        <Link
          onClick={() => navigateToPageHandler()}
          to="/"
          className={activeClassChecker("/")}
        >
          {t("brand-name")}
        </Link>

        <Link
          to="/intro"
          onClick={() => navigateToPageHandler()}
          className={activeClassChecker("/intro")}
        >
          {t("how-we-started")}
        </Link>

        <Link
          to="/about"
          onClick={() => navigateToPageHandler()}
          className={activeClassChecker("/about")}
        >
          {t("about-us")}
        </Link>

        <Link
          to="/services"
          onClick={() => navigateToPageHandler()}
          className={activeClassChecker("/services")}
        >
          {t("services")}
        </Link>

        <Link
          to="/projects"
          onClick={() => navigateToPageHandler()}
          className={activeClassChecker("/projects")}
        >
          {t("projects")}
        </Link>

        <Link
          to="/contact"
          onClick={() => navigateToPageHandler()}
          className={activeClassChecker("/contact")}
        >
          {t("contacts")}
        </Link>

        <select
          onChange={(e) => onLanguageChangeHandler(e.target.value)}
          value={selectedLang}
        >
          <option value="bg">🇧🇬 Български</option>
          <option value="en">🇬🇧 English</option>
          <option value="de">🇩🇪 Deutsch</option>
          <option value="rus">🇷🇺 Рускийй</option>
        </select>

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn nav-open-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
};

export default AppBar;
