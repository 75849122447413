import React from "react";
import { useTranslation } from "react-i18next";
import ofisImage from "../assets/ofisSnimka.jpg";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about">
      <div className="about-heading">
        <h1>{t("about-us-heading")}</h1>
      </div>
      <div className="about-image-text-wrapper">
        <div className="about-image">
          <img src={ofisImage} alt="#" className="about-img" />
        </div>
        <div className="about-text">
          <div className="about-p1">
            <p>{t("about-us-p1")}</p>
          </div>
          <div className="about-p2">
            <p>{t("about-us-p2")}</p>
          </div>
          <div className="about-p3">
            <p>{t("about-us-p3")}</p>
          </div>

          <div className="about-p4">
            <p>{t("about-us-p4")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
