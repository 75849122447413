import React from "react";
import "../App";
import { useTranslation } from "react-i18next";
import rabotnoBg from "../assets/bg.jpg";
import rabotnoEn from "../assets/en.jpg";
import rabotnoRus from "../assets/ru.jpg";
import rabotnoDe from "../assets/de.jpg";
import i18next from "i18next";

const Intro = () => {
  const { t } = useTranslation();

  const language = i18next.language;
  const renderWorkingTimeImage = () => {
    if (language === "en") {
      return (
        <img
          src={rabotnoEn}
          alt="#"
          // style={{ width: "600x", height: "600px" }}
          // className="home-page-services-img"
          className="intro-img"
        />
      );
    }

    if (language === "rus") {
      return (
        <img
          src={rabotnoRus}
          alt="#"
          // style={{ width: "600px", height: "600px" }}
          // className="home-page-services-img"
          className="intro-img"
        />
      );
    }

    if (language === "de") {
      return (
        <img
          src={rabotnoDe}
          alt="#"
          // style={{ width: "600px", height: "600px" }}
          // className="home-page-services-img"
          className="intro-img"
        />
      );
    }

    return (
      <img
        src={rabotnoBg}
        alt="#"
        // style={{ width: "600px", height: "600px" }}
        // className="home-page-services-img"
        className="intro-img"
      />
    );
  };

  return (
    <div className="intro">
      <div className="intro-heading">
        <h1>{t("how-we-started-heading")}</h1>
      </div>

      <div className="intro-image-text-wrapper">
        <div className="intro-image">{renderWorkingTimeImage()}</div>
        <div className="intro-text">
          <div className="intro-p1">
            <p>{t("how-we-started-p1")}</p>
          </div>
          <div className="intro-p2">{t("how-we-started-p2")}</div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
