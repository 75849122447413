import React, { useState } from "react";
import "../App";
import reklamaBgImage from "../assets/sigma-bg.png";
import reklamaRusImage from "../assets/sigma-russian.png";
import reklamaEngImage from "../assets/sigma-english.png";
import reklamaDeImage from "../assets/sigma-german.png";
import { useNavigate } from "react-router";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import maggyImage from "../assets/magi.jpg";
import SigmaLogo from "../assets/logo sigma2.jpg";
import silviaImage from "../assets/silvia.jpg";
import fatherImage from "../assets/bashtata.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Home = () => {
  const { t } = useTranslation();
  const [activeImage] = useState([silviaImage, fatherImage, maggyImage]);
  const [activeDescription] = useState([
    `${t("team-silvia")}`,
    `${t("team-father")}`,
    `${t("team-maggy")}`,
  ]);

  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const language = i18next.language;

  const onNextImageHandler = () => {
    if (index >= 2) {
      setIndex(0);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  const onPrevImageHandler = () => {
    if (index === 0) {
      setIndex(2);
    } else {
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  const renderPromoImage = () => {
    if (language === "en") {
      return (
        <img
          src={reklamaEngImage}
          alt="#"
          style={{ width: "540px", height: "300px", objectFit: "contain" }}
          className="home-page-services-img"
        />
      );
    }

    if (language === "rus") {
      return (
        <img
          src={reklamaRusImage}
          alt="#"
          style={{ width: "540px", height: "300px", objectFit: "contain" }}
          className="home-page-services-img"
        />
      );
    }

    if (language === "de") {
      return (
        <img
          src={reklamaDeImage}
          alt="#"
          style={{ width: "540px", height: "300px", objectFit: "contain" }}
          className="home-page-services-img"
        />
      );
    }

    return (
      <img
        src={reklamaBgImage}
        alt="#"
        style={{ width: "540px", height: "300px", objectFit: "contain" }}
        className="home-page-services-img"
      />
    );
  };

  return (
    <div className="home">
      <section id="showcase" className="showcase">
        <div className="section-content"></div>
        <img src={SigmaLogo} alt="#" className="home-page-logo" />
        <h1 className="showcase-title">{t("brand-name")}</h1>
      </section>
      <div className="home-after-showcase">
        <p>{t("bridge-of-trust")}</p>
      </div>
      <div>{renderPromoImage()}</div>
      <div className="home-description">
        <h3 className="brand-security-loyalty">{t("our-brand")}</h3>
      </div>

      <div>
        <h3 className="work-online">{t("we-work-with")}</h3>
      </div>

      <div className="home-buttons">
        <div>
          <button onClick={() => navigate("/about")}>{t("about-us")}</button>
        </div>
        <div>
          <button onClick={() => navigate("/services")}>{t("services")}</button>
        </div>
        <div>
          <button onClick={() => navigate("/contact")}>{t("contacts")}</button>
        </div>
      </div>

      <h1 className="our-team-heading">{t("our-team")}</h1>

      <div className="carousel-container">
        <div className="arrow-left" onClick={onPrevImageHandler}>
          <MdOutlineArrowBackIosNew size={30} color={"white"} />
        </div>
        <div className="carousel-image-container">
          <img
            src={activeImage[index]}
            alt="#"
            className="active-carousel-image"
          />
        </div>
        <div className="arrow-right" onClick={onNextImageHandler}>
          <MdOutlineArrowForwardIos size={30} color={"white"} />
        </div>
      </div>

      <div className="carousel-description">
        <p>{activeDescription[index]}</p>
      </div>
      <div className="dots">
        <div className={index === 0 ? "active-dot" : "dot"}></div>
        <div className={index === 1 ? "active-dot" : "dot"}></div>
        <div className={index === 2 ? "active-dot" : "dot"}></div>
      </div>
    </div>
  );
};

export default Home;
