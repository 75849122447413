import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Intro from "./components/Intro";
import Projects from "./components/Projects";
import Services from "./components/Services";
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import bgTranslations from "./translations/bg.json";
import engTranslations from "./translations/en.json";
import deTranslations from "./translations/de.json";
import rusTranslations from "./translations/rus.json";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { Suspense } from "react";

function App() {
  i18n.use(initReactI18next).init({
    resources: {
      bg: {
        translation: bgTranslations,
      },
      en: {
        translation: engTranslations,
      },
      de: {
        translation: deTranslations,
      },
      rus: {
        translation: rusTranslations,
      },
    },
    lng: localStorage.getItem("lang") ?? "bg",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    react: {
      useSuspense: false,
    },
  });

  return (
    <Suspense fallback="Loading...">
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/intro" element={<Intro />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;
