import React from "react";
import "../App";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="services">
      <div className="services-heading">
        <h1>{t("services-small-heading")}</h1>
      </div>

      <div className="services-img-text-wrapper">
        <div className="services-img-wrapper">
          <img
            src="https://images.unsplash.com/photo-1530970279610-8234f88384c3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80"
            alt="#"
            className="services-img"
          />
        </div>
        <div className="services-text-wrapper">
          <ul>
            <li>{t("services-written-translations-heading")}</li>
          </ul>
          <p>{t("services-written-translations-p1")}</p>
        </div>
      </div>

      <div className="services-img-text-wrapper">
        <div className="services-img-wrapper">
          <img
            src="https://media.istockphoto.com/photos/business-woman-wearing-headphones-watching-video-webinar-write-notes-picture-id1150384618?k=20&m=1150384618&s=612x612&w=0&h=xZ0TEbslBTNeThSKm-40L6w0DqcCd-3UwY48qbX0BqE="
            alt="#"
            className="services-img"
          />
        </div>
        <div className="services-text-wrapper">
          <ul>
            <li>{t("services-interpretation-heading")}</li>
          </ul>
          <p>{t("services-interpretation-p1")}</p>
          <p>{t("services-interpretation-p2")}</p>
        </div>
      </div>

      <div className="services-img-text-wrapper">
        <div className="services-img-wrapper">
          <img
            src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            alt="#"
            className="services-img"
          />
        </div>
        <div className="services-text-wrapper">
          <ul>
            <li>{t("services-legalisation-heading")}</li>
          </ul>
          <p>{t("services-legalisastion-p1")}</p>
          <p>{t("services-legalisastion-p2")}</p>
        </div>
      </div>

      <ul>
        <li>{t("services-visas-heading")}</li>
      </ul>

      <ul>
        <li>{t("services-other-activities")}</li>
      </ul>

      <p>{t("services-language-courses")}</p>
      <p>{t("services-individual-classes")}</p>
      <p>{t("services-copywriting")}</p>
      <p>{t("services-various-activities")}</p>
    </div>
  );
};

export default Services;
