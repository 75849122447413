import React from "react";
import "../App";
import { FaFacebook } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import mapImage from "../assets/map1.png";
const Contact = () => {
  const { t } = useTranslation();

  const openMapsHandler = () => {
    window.open(
      "https://www.google.com/maps/place/%D1%83%D0%BB.+%E2%80%9E%D0%A1%D0%BB%D0%B0%D0%B2%D1%8F%D0%BD%D1%81%D0%BA%D0%B0%E2%80%9C+59,+8000+%D0%91%D1%83%D1%80%D0%B3%D0%B0%D1%81+%D0%A6%D0%B5%D0%BD%D1%82%D1%8A%D1%80,+%D0%91%D1%83%D1%80%D0%B3%D0%B0%D1%81,+%D0%91%D1%8A%D0%BB%D0%B3%D0%B0%D1%80%D0%B8%D1%8F/@42.4953031,27.4757418,17z/data=!4m5!3m4!1s0x40a694c1002d3c79:0x5d34d4bfc522b462!8m2!3d42.4953031!4d27.4757418?force=pwa&source=mlapk",
      "_blank"
    );
  };

  return (
    <div className="contact">
      <div className="contact-heading">
        <h1>{t("contacts-heading")}</h1>
      </div>
      <h3>{t("contacts-heading-small")}</h3>
      <p>{t("contacts-sylvia")}</p>
      <p> {t("contacts-address")}</p>
      <img src={mapImage} alt="#" className="contacts-map-img" />{" "}
      <button className="btn-location" onClick={openMapsHandler}>
        {t("contacts-open-maps-btn")}
      </button>
      <p className="mobile-number">{t("contacts-mobile")}</p>
      <p>
        Email: <span style={{ color: "#f98d2f" }}> sisprevodi@gmail.com</span>
      </p>
      <p>
        Facebook:{" "}
        <span>
          {" "}
          <FaFacebook
            size={40}
            color={"#1678f2"}
            onClick={() =>
              window.open("https://www.facebook.com/silviya.georgieva.148")
            }
            className="facebook-icon"
          />{" "}
        </span>
      </p>
      <p>
        {t("contacts-business-facebook")}
        <span>
          {" "}
          <FaFacebook
            size={40}
            color={"#1678f2"}
            onClick={() =>
              window.open("https://www.facebook.com/silviyageorgieva1980/")
            }
            className="facebook-icon"
          />{" "}
        </span>
      </p>
      <p>
        <b>{t("contacts-skype")}</b> SISEN
      </p>
      <div className="contact-form">
        <form name="contact-form" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact-form" />
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label htmlFor="name">
              {t("contacts-name-field")}{" "}
              <span className="required-span">{`(${t("required")})`}</span>
            </label>{" "}
            <br />
            <input type="text" id="name" name="name" required />
          </p>
          <p>
            <label htmlFor="email">
              {t("contacts-email-field")}{" "}
              <span className="required-span">{`(${t("required")})`}</span>
            </label>{" "}
            <br />
            <input type="email" id="email" name="email" required />
          </p>
          <p>
            <label htmlFor="message">{t("contacts-message-field")}</label>{" "}
            <br />
            <textarea id="message" name="message" required></textarea>
          </p>
          <button type="submit">{t("btn-send")}</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
