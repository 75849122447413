import React from "react";
import { Link } from "react-router-dom";
import '../App'; 

const NotFound = () => {
  return (
    <div className="not-found">
      <h1>Страницата не беше намерена!</h1>

      <Link to="/">Обратно към начална страница</Link>
    </div>
  );
};

export default NotFound;
